import React, { Component } from "react";
import moment from "moment";
import "moment/locale/ru";
import { DayPickerSingleDateController } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import "../sass/calendar.sass";
import isSameDay from "react-dates/lib/utils/isSameDay";
import axios from "axios";
import { AUTH_API_URL } from "../lib/env";
import { Container, Button, LinearProgress } from "@material-ui/core";
import DefaultAvatar from "../img/default-avatar.jpg";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

export default class CalendarPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      token: localStorage.getItem("token"),
      focused: true,
      date: moment(),
      users: [],
      highLightedDates: [],
      isLoading: false,
      events: [],
      daysoffList: [],
      daysoff: {},
      foundedEvents: false,
      foundedDaysoff: false,
      minDateReached: false,
      maxDateReached: false,
    };

    this.handleDateClick = this.handleDateClick.bind(this);
    this.renderDay = this.renderDay.bind(this);
    this.checkMonth = this.checkMonth.bind(this);
  }

  handleDateClick(date) {
    this.setState(
      {
        date: date,
        foundedDaysoff: false,
        foundedEvents: false,
      },
      () => {
        const choosenDate = moment(date).format("DD.MM.YYYY");
        for (let i = 0; i < Object.keys(this.state.users).length; i += 1) {
          if (
            choosenDate ===
            moment(this.state.highLightedDates[i]).format("DD.MM.YYYY")
          ) {
            this.setState(
              {
                events: this.state.users[Object.keys(this.state.users)[i]],
                foundedEvents: true,
              },
              () => console.log(this.state.events)
            );
          }
        }
        for (let i = 0; i < this.state.daysoffList.length; i += 1) {
          if (
            choosenDate ===
            moment(this.state.daysoffList[i].date, "DD.MM.YYYY").format(
              "DD.MM.YYYY"
            )
          ) {
            this.setState(
              {
                daysoff: Object.assign(this.state.daysoffList[i]),
                foundedDaysoff: true,
              },
              () => console.log(this.state.daysoff)
            );
          }
        }
      }
    );
  }

  componentDidMount() {
    this.getUsersData();
    this.checkMonth();
  }

  getBirthDates() {
    const birthDates = Object.keys(this.state.users);

    this.setState(
      {
        highLightedDates: birthDates.map((date) => moment(date, "DD.MM.YYYY")),
        isLoading: false,
      },
      () => {
        console.log(this.state.highLightedDates);
      }
    );
  }

  getUsersData() {
    this.setState({ isLoading: true });

    let self = this;
    axios
      .get(AUTH_API_URL + "/users/getBirthDates", {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      })
      .then(function (response) {
        console.log(response.data);
        self.setState(
          {
            users: response.data,
          },
          () => {
            self.getBirthDates();
          }
        );
      })
      .catch(function (error) {
        self.setState({ isLoading: false });
        console.log(error.message);
      });
  }

  getPhoto(photo) {
    if (photo === null || photo === "" || photo === "photo") {
      return <img src={DefaultAvatar} alt="default avavtar" />;
    } else {
      return <img src={photo} alt="avatar" />;
    }
  }

  renderDay(day) {
    for (let i = 0; i < this.state.daysoffList.length; i += 1) {
      if (
        moment(this.state.daysoffList[i].date, "DD.MM.YYYY").format(
          "DD.MM.YYYY"
        ) === moment(day, "DD.MM.YYYY").format("DD.MM.YYYY")
      ) {
        return <span>{day.format("D")}</span>;
      }
    }
    return moment(day).format("D");
  }

  checkMonth(date) {
    const minDate = moment().format("01.YYYY");
    const maxDate = moment().format("12.YYYY");
    const currentDate = moment(date).format("MM.YYYY");

    if (currentDate === minDate) {
      this.setState({
        minDateReached: true,
      });
      return console.log("Min Date");
    } else {
      this.setState({
        minDateReached: false,
        maxDateReached: false,
      });
    }

    if (currentDate === maxDate) {
      this.setState({
        maxDateReached: true,
      });
      return console.log("Max Date");
    } else {
      this.setState({
        minDateReached: false,
        maxDateReached: false,
      });
    }
  }

  render() {
    return (
      <Container className="calendar">
        <div className="calendar__title">
          <Button
            className="button_small button_grey_color"
            onClick={() => this.props.history.push("/profile")}
          >
            <ChevronLeftIcon />
          </Button>
          <h1>Календарь событий</h1>
        </div>
        {this.state.isLoading ? (
          <LinearProgress />
        ) : (
          <div className="calendar__content block">
            <DayPickerSingleDateController
              navPosition="navPositionBottom"
              navPrev={
                <button
                  className={"month-btn month-btn_prev"}
                  disabled={this.state.minDateReached}
                >
                  Prev
                </button>
              }
              navNext={
                <button
                  className={"month-btn month-btn_next"}
                  disabled={this.state.maxDateReached}
                >
                  Next
                </button>
              }
              onNextMonthClick={this.checkMonth}
              onPrevMonthClick={this.checkMonth}
              verticalHeight={330}
              hideKeyboardShortcutsPanel={true}
              orientation="vertical"
              firstDayOfWeek={1}
              date={this.state.date}
              onDateChange={this.handleDateClick}
              focused={this.state.focused}
              onFocusChange={({ focused }) => this.setState({ focused })}
              numberOfMonths={1}
              isDayHighlighted={(day1) =>
                this.state.highLightedDates.some((day2) =>
                  isSameDay(day1, day2)
                )
              }
              renderDayContents={this.renderDay}
            />

            <div className="date-info">
              <div className="date-info__title">
                <h2>{moment(this.state.date).format("DD MMMM YYYY")}</h2>
              </div>

              <div className="events">
                {this.state.foundedEvents && (
                  <React.Fragment>
                    <h3>Дни рождения</h3>
                    {this.state.foundedEvents &&
                      this.state.events.map((event, index) => (
                        <div className="events__item event" key={index}>
                          <div className="event__photo">
                            {this.getPhoto(event.photo)}
                          </div>
                          <div className="event__text">
                            <h5>
                              {event.firstName} {event.lastName}
                            </h5>
                            <p>
                              Подразделение: <span>{event.division}</span>
                            </p>
                            <p>
                              Должность: <span>{event.position}</span>
                            </p>
                          </div>
                        </div>
                      ))}
                  </React.Fragment>
                )}
                {this.state.foundedDaysoff && (
                  <React.Fragment>
                    <h3>Праздники</h3>
                    <h2>{this.state.daysoff.name}</h2>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        )}
      </Container>
    );
  }
}
