import React from 'react'
import { TextField } from '@material-ui/core'
import InputMask from 'react-input-mask'

const MaskedTextInput = ({
                             mask,
                             label,
                             value,
                             change,
                             name,
                             type,
                             styles,
                             disabled = false
                         }) => {
    return (
        <InputMask
            mask={mask}
            name={name}
            maskChar={''}
            value={value}
            onChange={change}
            disabled={disabled}
        >
            {inputProps => (
                <TextField
                    {...inputProps}
                    type={type}
                    style={styles}
                    fullWidth
                    label={label}
                />
            )}
        </InputMask>
    )
}

export default MaskedTextInput