import firebase from "@firebase/app";
import "@firebase/messaging";

const initializedFirebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyAArJSByhw-iRaHuDNYnJUk_GgmW1LVGqo",
  authDomain: "asterx-a1f04.firebaseapp.com",
  databaseURL: "https://asterx-a1f04.firebaseio.com",
  projectId: "asterx-a1f04",
  storageBucket: "asterx-a1f04.appspot.com",
  messagingSenderId: "793692714133",
  appId: "1:793692714133:web:b67d6e04aa92b8b456979c",
  measurementId: "G-WFEWPEEHLG"
});

let messaging = null;
if (firebase.messaging.isSupported()) {
  messaging = initializedFirebaseApp.messaging();
  messaging.usePublicVapidKey(
    "BDUROKYybHUf5Tk0bUBuXszSzEvNi4Z_xahSynggDwRzTB0IBY_1o8MUA-hdB_cdCNg3q7LjdTDctTeuouN5nVo"
  );
}



export { messaging };