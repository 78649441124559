import React, { Component } from "react";
import {
  Badge,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Snackbar,
} from "@material-ui/core";
import CarIcon from "@material-ui/icons/DriveEta";
import CarOutlinedIcon from "@material-ui/icons/DriveEtaOutlined";
import DollarIcon from "@material-ui/icons/MonetizationOn";
import TagIcon from "@material-ui/icons/LocalOffer";
import FilterIcon from "@material-ui/icons/FilterList";
import ParserIcon from "@material-ui/icons/BugReport";
import UploadIcon from "@material-ui/icons/CloudUpload";
import PrintIcon from "@material-ui/icons/Print";
import UserIcon from "@material-ui/icons/AccountCircle";
import CarWashIcon from "@material-ui/icons/LocalCarWash";
import CalendarIcon from "@material-ui/icons/DateRange";
import { withRouter } from "react-router-dom";
import ReportIcon from "@material-ui/icons/ListAlt";
import LikeIcon from "@material-ui/icons/ThumbUpAlt";
import ClientIcon from "@material-ui/icons/Face";
import ExploreIcon from "@material-ui/icons/Explore";
import PinIcon from "@material-ui/icons/PersonPin";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import KeyIcon from "@material-ui/icons/VpnKey";
import VeditorIcon from "@material-ui/icons/BorderColor";
import DashboardIcon from "@material-ui/icons/BarChart";
import CCIcon from "@material-ui/icons/ContactPhone";
import DocsIcon from "@material-ui/icons/MenuBook";
import XXXIcon from "@material-ui/icons/Beenhere";
import BIIcon from "@material-ui/icons/TrendingUp";
import ContractsIcon from "@material-ui/icons/AllInbox";
import SeoIcon from "@material-ui/icons/EmojiPeople";
import SadminIcon from "@material-ui/icons/ImportantDevices";
import WikiIcon from "@material-ui/icons/ImportContacts";
import ExtensionIcon from "@material-ui/icons/Extension";
import LoyaltyIcon from "@material-ui/icons/Loyalty";
import PeopleIcon from "@material-ui/icons/People";
import HelpIcon from "@material-ui/icons/Help";
import AirportShuttleIcon from "@material-ui/icons/AirportShuttle";
import ScheduleIcon from "@material-ui/icons/Schedule";
import SecurityIcon from "@material-ui/icons/Security";
import QrCode2Icon from "@material-ui/icons/CropFree";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import AllInclusiveIcon from "@material-ui/icons/AllInclusive";
import BuildIcon from "@material-ui/icons/Build";
import PhoneCallbackIcon from "@material-ui/icons/PhoneCallback";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutlined";
import FavoriteIcon from "@material-ui/icons/Favorite";
import PaymentIcon from "@material-ui/icons/Payment";
import BallotIcon from "@material-ui/icons/Ballot";
import AcUnitIcon from "@material-ui/icons/AcUnit";
import ShopIcon from "@material-ui/icons/Shop";
import LanguageIcon from "@material-ui/icons/Language";
import SearchIcon from "@material-ui/icons/Search";
import DeviceUnknownIcon from "@material-ui/icons/DeviceUnknown";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import AlbumIcon from "@material-ui/icons/Album";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import TextFieldsIcon from "@material-ui/icons/TextFields";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MuiAlert from "@material-ui/lab/Alert";
import { parseJwt } from "../lib/util";
import axios from "axios";
import { AUTH_API_URL, MISC_API_URL, ML_API_URL, SALES_API_URL } from "../lib/env";
import { CastForEducation, School } from "@material-ui/icons";
import MailIcon from "@material-ui/icons/Mail";
import DescriptionIcon from "@material-ui/icons/Description";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import AddPhotoIcon from "@material-ui/icons/AddAPhoto";
import HeadpsetIcon from "@material-ui/icons/Headset";
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';

class UserMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isReportsOpen: false,
      BImenuAnchor: null,
      user: null,
      dashboards: [],
      missingPlan: false,
      unreadMessages: 0,
      csContractsNotificationsCount: 0,
      csV2NotificationsCount: 0,
      cpPayNotificationsCount: 0
    };
  }

  getCsContractsNotificationsCount() {
    let self = this;
    axios
        .get(SALES_API_URL + "/sales/saleOnSppApprovalCounter", {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        })
        .then(function (response) {
          if (response.data) {
            self.setState({ csContractsNotificationsCount: response.data });
          }
          console.log(response.data);
        })
        .catch(function (error) {
          console.log(error.message);
          if (error.message.includes("401") || error.message.includes("403")) {
            self.props.history.push("/signin");
          }
        });
  }

  compare(a, b) {
    if (a.project.name < b.project.name) {
      return -1;
    }
    if (a.project.name > b.project.name) {
      return 1;
    }
    return 0;
  }

  getDashboards() {
    let self = this;
    axios
      .get(AUTH_API_URL + "/users/getUserByToken", {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then(function (response) {
        if (response.data.dashboardList) {
          self.setState({ dashboards: response.data.dashboardList });
        }
        console.log(response.data);
      })
      .catch(function (error) {
        console.log(error.message);
        if (error.message.includes("401") || error.message.includes("403")) {
          self.props.history.push("/signin");
        }
      });
  }

  componentDidMount() {
    if (!localStorage.getItem("token")) {
      this.props.history.push("/signin");
    }
    let user = parseJwt(localStorage.getItem("token"));
    this.setState({ user });
    console.log(user);

    if (
      user.roleList.filter(
        (role) =>
          (role.code === "HEAD_SALES_CP" && role.project.code === "DASH") ||
          (role.code === "HEAD_SALES_CS" && role.project.code === "DASH")
      ).length > 0
    ) {
      this.getPlanMissing(user.sub);
    }

    if (user.dashboardCodes && user.dashboardCodes.length > 0) {
      this.getDashboards();
    }

    this.getUnreadMessages();
  }

  getUnreadMessages() {
    let self = this;
    axios
      .get(MISC_API_URL + "/support/countActiveChats", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then(function (response) {
        self.setState({ unreadMessages: response.data });
      })
      .catch(function (error) {
        console.log(error.message);
        if (error.message.includes("401") || error.message.includes("403")) {
          self.props.history.push("/signin");
        }
      });
  }

  getPlanMissing(username) {
    let self = this;
    axios
      .post(
        ML_API_URL + "/ml/missingPlan",
        {
          username: username,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then(function (response) {
        self.setState({ missingPlan: !response.data });
      })
      .catch(function (error) {
        console.log(error.message);
        if (error.message.includes("401") || error.message.includes("403")) {
          self.props.history.push("/signin");
        }
      });
  }

  render() {
    let menus = [];
    let roles = this.props.user.roles;
    roles.sort(this.compare);

    for (let role of this.props.user.roles) {
      switch (role.project.code) {
        case "FB":
          menus.push(
            <ListItem
              button
              key={role.id}
              onClick={() => {
                this.props.history.push("/carfin");
                this.props.hideDrawer();
              }}
            >
              <ListItemIcon style={{ marginLeft: "7px" }}>
                <DollarIcon />
              </ListItemIcon>
              <ListItemText primary={role.project.name} />
            </ListItem>
          );
          break;
        case "CP":
          if (
            role.code === "HEAD_PRICER" ||
            role.code === "CM" ||
            role.code === "HEAD_BRANCH" ||
            role.code === "CASHIER" ||
            role.code === "SUPER_HEAD_SALES" ||
            role.code === "HEAD_SALES" ||
            role.code === "HEAD_SERVICE" ||
            role.code === "GUARANTEE_MANAGER" ||
            role.code === "SUPER_HEAD_AND_BRANCH" ||
            role.code === "SECURITY"
          ) {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/valuation");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <CarIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          } else if (role.code === "PRICER") {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/pricer");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <CarIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          } else if (role.code === "PURCHASER") {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/buyer");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <CarIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          } else if (role.code === "LOGIST") {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/logist");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <CarIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          } else if (role.code === "OSS" || role.code === "HEAD_OSS") {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/oss");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <CarIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          } else if (
            role.code === "CRIMINALIST" ||
            role.code === "SUPER_CRIMINALIST"
          ) {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/forensic");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <CarIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          } else if (role.code === "MASTER_RECEIVER") {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/inspector");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <CarIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "PPP":
          if (
            role.code === "STOCK_CHECKER" ||
            role.code === "STOCKER" ||
            role.code === "MASTER_RECEIVER" ||
            role.code === "HEAD_PPP" ||
            role.code === "SENIOR_TECHNIC" ||
            role.code === "HEAD_SERVICE" ||
            role.code === "HEAD_SALES" ||
            role.code === "HEAD_BRANCH" ||
            role.code === "SUPPLIER" ||
            role.code === "DESCRIPTOR" ||
            role.code === "HEAD_SALES_CS" ||
            role.code === "MANAGER_CS" ||
            role.code === "HEAD_STOCKER" ||
            role.code === "HEAD_PRICER" ||
            role.code === "PHOTOGRAPH"
          ) {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/ppp");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <CarWashIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "CAL":
          if (
            role.code === "USER" ||
            role.code === "PURCHASER" ||
            role.code === "HEAD_SALES" ||
            role.code === "HEAD_SALES_CS" ||
            role.code === "TECHNIC" ||
            role.code === "MANAGER_SALES"
          ) {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/calendar");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <CalendarIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "WP":
          if (role.code === "USER") {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/welcome");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <ExploreIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "MM":
          if (role.code === "MOBILE_ADMIN") {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/mm");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <PinIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "CS":
          if (
            role.code === "LOGIST" ||
            role.code === "FIN_MANAGER" ||
            role.code === "MANAGER" ||
            role.code === "SUPER_HEAD_SALES" ||
            role.code === "HEAD_SALES"
          ) {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/sales");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <LocalOfferIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "FL":
          if (role.code === "OPERATOR") {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/filter");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <FilterIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "PS":
          if (role.code === "OPERATOR") {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/parser");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <ParserIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "CAR":
          if (
            role.code === "STOCK_CHECKER" ||
            role.code === "PHOTOGRAPH" ||
            role.code === "CAR_EDITOR" ||
            role.code === "STOCKER" ||
            role.code === "ADMIN" ||
            role.code === "HEAD_SALES" ||
            role.code === "KOLESA_MAN" ||
            role.code === "HEAD_AND_REQUEST" ||
            role.code === "LOGISTICS" ||
            role.code === "HEAD_SALES_CS" ||
            role.code === "SUPER_HEAD_SALES" ||
            role.code === "MANAGER_NEW_AUTO" ||
            role.code === "ROP_NEW_AUTO" ||
            role.code === "CQ" ||
            role.code === "ATZ" ||
            role.code === "VIDEOGRAPH" ||
            role.code === "HEAD_BRANCH" ||
            role.code === "HEAD" ||
            role.code === "CM"
          ) {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/cars");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <CarOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "CL":
          if (role.code === "ADMIN") {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/clients");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <ClientIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "VE":
          if (role.code === "VALUE_EDITOR") {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/veditor");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <VeditorIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "PP":
          if (role.code === "PRICE_PRINTER") {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/pp");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <PrintIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "PF":
          menus.push(
            <ListItem
              button
              key={role.id}
              onClick={() => {
                this.props.history.push("/perekup");
                this.props.hideDrawer();
              }}
            >
              <ListItemIcon style={{ marginLeft: "7px" }}>
                <TagIcon />
              </ListItemIcon>
              <ListItemText primary={role.project.name} />
            </ListItem>
          );
          break;
        case "UA":
          if (role.code === "ADMIN") {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/user_admin");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <UserIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "KEY":
          if (
            role.code === "KEEPER" ||
            role.code === "USER" ||
            role.code === "PARKING" ||
            role.code === "CASHIER"
          ) {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/keys");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <KeyIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "CC":
          if (role.code === "SCRIPT_ADMIN" || role.code === "OPERATOR") {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/cc");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <CCIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "DOCS":
          if (role.code === "VIEWER" || role.code === "ADMIN") {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/docs");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <DocsIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "XXX":
          if (
            role.code === "NEWS_EDITOR" ||
            role.code === "MODERATOR" ||
            role.code === "SUPPORTER" ||
            role.code === "CM" ||
            role.code === "HEAD_CM"
          ) {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/xxx");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <XXXIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "XXX_CUSTOMER_SUPPORT":
          if (role.code === "SUPER_MODERATOR" || role.code === "MODERATOR") {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/customer-support");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <DeviceUnknownIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        // case 'BI':
        //   if (role.code === 'CEO' || role.code === 'USER') {
        //     menus.push(
        //       <ListItem button key={role.id}
        //                 onClick={(e) => { this.setState({menuAnchor: e.currentTarget}) }}>
        //         <ListItemIcon style={{ marginLeft: '7px' }}><BIIcon /></ListItemIcon>
        //         <ListItemText primary={role.project.name} />
        //       </ListItem>
        //     );
        //   }
        //   break;
        case "MISC":
          if (role.code === "CONTRACTS_EDITOR") {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/contracts");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <ContractsIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "SEO":
          if (role.code === "OPERATOR") {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/seo");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <SeoIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "WIKI":
          if (
            role.code === "ADMIN" ||
            role.code === "PHOTOGRAPH" ||
            role.code === "MODERATOR" ||
            role.code === "MANAGER_AMP" ||
            role.code === "RGP_AMP" ||
            role.code === "ROP_AMP" ||
            role.code === "BUYER_AMP" ||
            role.code === "RGV_AMP" ||
            role.code === "ROV_AMP" ||
            role.code === "HEAD_AMP" ||
            role.code === "CQ" ||
            role.code === "CQ_SUPER" ||
            role.code === "HEAD_CLIENT_CARE" ||
            role.code === "CX" ||
            role.code === "HEAD_CX" ||
            role.code === "ATZ" ||
            role.code === "MARKETING" ||
            role.code === "HR" ||
            role.code === "TEACHER" ||
            role.code === "ROC_AMP" ||
            role.code === "MOC_AMP" ||
            role.code === "HR" ||
            role.code === "ARARAT" ||
            role.code === "PRICER" ||
            role.code === "STOKER" ||
            role.code === "MOP_OPA" ||
            role.code === "ROP_OPA" ||
            role.code === "HEAD_OPA" ||
            role.code === "MP_AMP" ||
            role.code === "ROS_AMP" ||
            role.code === "SPP" ||
            role.code === "PARTS_STOCKER" ||
            role.code === "FIN_MANAGER" ||
            role.code === "STAND_BY" ||
            role.code === "HEAD_BRANCH"
          ) {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/wiki");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <WikiIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "SADMIN":
          if (role.code === "ADMIN") {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/sadmin");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <SadminIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "WWW_LADA_ADMIN":
          if (role.code === "ADMIN") {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/wwwladaadmin");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <ExtensionIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "H_CS":
          if (role.code === "HEAD_SALES" || role.code === "MANAGER") {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/hypersales");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <LoyaltyIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "OR_UA":
          if (role.code === "ADMIN") {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/orionuseradmin");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <PeopleIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "CS_NEWAUTO":
          if (
            role.code === "ATZ" ||
            role.code === "LOGIST" ||
            role.code === "FIN_MANAGER" ||
            role.code === "FLEET_MANAGER" ||
            role.code === "SUPER_HEAD_SALES" ||
            role.code === "HEAD_SALES" ||
            role.code === "MANAGER"
          ) {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/newcarsales");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <AirportShuttleIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "DEALER_ADMIN":
          if (role.code === "ADMIN") {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/branch");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <ScheduleIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "EDU":
          if (
            role.code === "TEACHER" ||
            role.code === "RECRUITER" ||
            role.code === "HEAD_SALES"
          ) {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/edu-journal");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <CastForEducation />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "CAR_GUARANTEE":
          if (
            role.code === "MANAGER" ||
            role.code === "HEAD_SERVICE" ||
            role.code === "ADMIN"
          ) {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/guarantee");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <SecurityIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "QR":
          if (
            role.code === "MANAGER" ||
            role.code === "HEAD" ||
            role.code === "SECURITY"
          ) {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/qr");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <QrCode2Icon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "CAR_PRICING":
          if (role.code === "INITIATOR" || role.code === "APPROVER") {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/car-pricing");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <MonetizationOnIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "BX":
          if (
            role.code === "CC" ||
            role.code === "HEAD_SALES" ||
            role.code === "CQ" ||
            role.code === "ADMIN" ||
            role.code === "ORION" ||
            role.code === "CQ_SUPER" ||
            role.code === "MARKETING" ||
            role.code === "CQ_SUPER"
          ) {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/bipiumx");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <PhoneCallbackIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "PPO":
          if (
            role.code === "MP" ||
            role.code === "OPERATOR" ||
            role.code === "HEAD_PPO" ||
            role.code === "SERVICE_ADMIN" ||
            role.code === "MECHANIC"
          ) {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/ppo");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <BuildIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "LI":
          if (role.code === "LIMIT_EDITOR") {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/limits");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <RemoveCircleOutlineIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "CP_PAY":
          if (
            role.code === "FD" ||
            role.code === "SPP" ||
            role.code === "FinDir" ||
            role.code === "ACCOUNTANT" ||
            role.code === "MANAGEMENT" ||
            role.code === "CASHIER"
          ) {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/cp_pay");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <PaymentIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "REPORT_APP_FORMS":
          if (role.code === "USER") {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/report_app_forms");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <BallotIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "ARABAX":
          if (
            role.code === "ADMIN" ||
            role.code === "HEAD_BUYER" ||
            role.code === "MOV"
          ) {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/arabax");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <ShopIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "CS_CONTRACTS":
          if (
            role.code === "FIN_MANAGER" ||
            role.code === "FS_HEAD_SALES" ||
            role.code === "CASHIER" ||
            role.code === "FS_SUPER_HEAD_SALES" ||
            role.code === "FIN_MANAGER_NEWCAR" ||
            role.code === "FIN_MANAGER_AMP_NEWCAR" ||
            role.code === "SPP"
          ) {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/cs_contracts");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  {/*<Badge badgeContent={4} color="secondary">*/}
                    <DescriptionIcon />
                  {/*</Badge>*/}
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "BINDING":
          if (role.code === "ADMIN") {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/binding");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <SupervisorAccountIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "C2C":
          if (role.code === "ADMIN" || role.code === "MANAGER") {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/c2c");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <PeopleOutlineIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "XXX_NEW_AUTO":
          if (role.code === "CM" || role.code === "SUPER_MODERATOR") {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/new_auto_admin");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <AddPhotoIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "CS_V2":
          if (
            ["MANAGER", "HEAD_SALES", "SUPER_HEAD_SALES", "SPP"].includes(role.code)
          ) {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/renewed_cs");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <LocalOfferIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "TIRES_AMP":
          if (
            role.code === "RECEIVER" ||
            role.code === "PRICER" ||
            role.code === "HEAD_BRANCH" ||
            role.code === "ADMIN"
          ) {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/tires");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <AlbumIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "TIRES_DESCRIPTION":
          if (role.code === "MODERATOR" || role.code === "ADMIN") {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/tires-description");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <TextFieldsIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "CARFIN":
          if (
            role.code === "MANAGER_AMP_NEWCAR" ||
            role.code === "MANAGER_NEWCAR" ||
            role.code === "HEAD_FS"
          ) {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/renewed-carfin");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <AttachMoneyIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "EDOC":
          if (
              role.code === "SIGNER" ||
              role.code === "USER"
          ) {
            menus.push(
                <ListItem
                    button
                    key={role.id}
                    onClick={() => {
                      this.props.history.push("/sign-docs");
                      this.props.hideDrawer();
                    }}
                >
                  <ListItemIcon style={{ marginLeft: "7px" }}>
                    <DescriptionIcon />
                  </ListItemIcon>
                  <ListItemText primary={role.project.name} />
                </ListItem>
            );
          }
          break;
        case "SECURITY_QR":
          if (
            role.code === "SECURITY" ||
            role.code === "HEAD_BRANCH" ||
            role.code === "ADMIN"
          ) {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/security_qr");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <VerifiedUserIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        // case "AX_SUPPORT_V2":
        //   if (role.code === "USER") {
        //     menus.push(
        //       <ListItem
        //         button
        //         key={role.id}
        //         onClick={() => {
        //           this.props.history.push("/support_v2");
        //           this.props.hideDrawer();
        //         }}
        //       >
        //         <ListItemIcon style={{ marginLeft: "7px" }}>
        //           <Badge
        //             badgeContent={this.state.unreadMessages}
        //             color="secondary"
        //           >
        //             <HeadpsetIcon />
        //           </Badge>
        //         </ListItemIcon>
        //         <ListItemText primary={role.project.name} />
        //       </ListItem>
        //     );
        //   }
        //   break;
        case "AX_SUPPORT":
          if (role.code === "ADMIN_HD" || role.code === "ADMIN") {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/support");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <FavoriteIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>
            );
          }
          break;
        case "BONUS":
          if (role.code === "USER" || role.code === "ADMIN") {
            menus.push(
                <ListItem
                    button
                    key={role.id}
                    onClick={() => {
                      this.props.history.push("/bonus");
                      this.props.hideDrawer();
                    }}
                >
                  <ListItemIcon style={{ marginLeft: "7px" }}>
                    <EmojiEventsIcon />
                  </ListItemIcon>
                  <ListItemText primary={role.project.name} />
                </ListItem>
            );
          }
          break;
        default:
          document.title = "AsterX";
          break;
      }
    }

    menus.push(
      <ListItem
        button
        key={"support"}
        onClick={() => {
          this.props.history.push("/support_v2");
          this.props.hideDrawer();
        }}
      >
        <ListItemIcon style={{ marginLeft: "7px" }}>
          <Badge badgeContent={this.state.unreadMessages} color="secondary">
            <HeadpsetIcon />
          </Badge>
        </ListItemIcon>
        <ListItemText primary={"Поддержка"} />
      </ListItem>
    );

    menus.push(
      <ListItem
        button
        key={"News"}
        onClick={() => {
          this.props.history.push("/news");
          this.props.hideDrawer();
        }}
      >
        <ListItemIcon style={{ marginLeft: "7px" }}>
          <LanguageIcon />
        </ListItemIcon>
        <ListItemText primary={"News"} />
      </ListItem>
    );

    menus.push(
      <ListItem
        button
        key={"staff-list"}
        onClick={() => {
          this.props.history.push("/staff-list");
          this.props.hideDrawer();
        }}
      >
        <ListItemIcon style={{ marginLeft: "7px" }}>
          <SearchIcon />
        </ListItemIcon>
        <ListItemText primary={"Сотрудники"} />
      </ListItem>
    );

    menus.push(
      <ListItem
        button
        key={"Superset"}
        onClick={() => {
          window.open("https://mail.aster.kz/SOGo/", "_blank");
          this.props.hideDrawer();
        }}
      >
        <ListItemIcon style={{ marginLeft: "7px" }}>
          <MailIcon />
        </ListItemIcon>
        <ListItemText primary={"Почта"} />
      </ListItem>
    );

    menus.push(
      <ListItem
        button
        key={"Edu"}
        onClick={() => {
          window.open("https://ed.aster-auto.kz/login/index.php", "_blank");
          this.props.hideDrawer();
        }}
      >
        <ListItemIcon style={{ marginLeft: "7px" }}>
          <School />
        </ListItemIcon>
        <ListItemText primary={"Обучение"} />
      </ListItem>
    );

    menus.push(
      <ListItem
        button
        key={"Superset"}
        onClick={() => {
          window.open("http://analytics.asterx.kz/superset/welcome/", "_blank");
          this.props.hideDrawer();
        }}
      >
        <ListItemIcon style={{ marginLeft: "7px" }}>
          <AllInclusiveIcon />
        </ListItemIcon>
        <ListItemText primary={"Аналитика"} />
      </ListItem>
    );

    function Alert(props) {
      return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    return (
      <div>
        <Snackbar open={this.state.missingPlan}>
          <Alert severity="error">Не выставлены планы!</Alert>
        </Snackbar>

        <List>{menus}</List>

        <Menu
          id="bi-menu"
          anchorEl={this.state.menuAnchor}
          keepMounted
          open={Boolean(this.state.menuAnchor)}
          onClose={() => this.setState({ menuAnchor: null })}
        >
          {this.state.user &&
            this.state.dashboards &&
            this.state.dashboards.length > 0 &&
            this.state.dashboards.map((dashboard) => (
              <MenuItem
                key={dashboard.code}
                onClick={() => {
                  this.props.history.push({
                    pathname: "/bi",
                    state: { url: dashboard.url },
                  });
                  this.props.hideDrawer();
                  this.setState({ menuAnchor: null });
                }}
              >
                {dashboard.name}
              </MenuItem>
            ))}
        </Menu>
      </div>
    );
  }
}

export default withRouter(UserMenu);
