export const UTP_QS = [
  {
    question: "Какими агрегатами оснащена Omoda C5 1.5T 2WD ?",
    utpNo: 101,
    answers: [
      {
        name: "Робот DCT7 + ДВС 1.5T 157лс",
        correct: false,
      },
      {
        name: "Вариатор CVT18 +ДВС 1.5T 147лс",
        correct: false,
      },
      {
        name: "Вариатор CVT25 + ДВС 1.5T 147лс",
        correct: true,
      },
      {
        name: "Вариатор CVT25 + ДВС 1.5T 157лс",
        correct: false,
      },
    ],
  },
  {
    question: "Какое описание характерно для Omoda C5 ?",
    utpNo: 102,
    answers: [
      {
        name: "Футуристичный, спортивный Фастбек для молодых людей",
        correct: true,
      },
      {
        name: "Рестайлинговая версия с новым дизайном на базе Chery Tigo 7Pro",
        correct: false,
      },
      {
        name: "Компактный городской кроссовер для девушек",
        correct: false,
      },
      {
        name: "Начинка Chery но в премиум исполнении",
        correct: false,
      },
    ],
  },
  {
    question:
      "Модель Omoda C5 создавалась в тесной кооперации с инженерами каких брендов?",
    utpNo: 103,
    answers: [
      {
        name: "Jaguar Land Rover & Lotus",
        correct: true,
      },
      {
        name: "Volvo",
        correct: false,
      },
      {
        name: "Exeed",
        correct: false,
      },
      {
        name: "Volkswagen",
        correct: false,
      },
    ],
  },
  {
    question:
      "Что из нижеперечисленного является верным и применяется в ДВС Omoda S5 1.5T ?",
    utpNo: 104,
    answers: [
      {
        name: "Чугунный блок цилиндров и равно-длинный выпускной коллектор",
        correct: false,
      },
      {
        name: "Чугунный блок цилиндров и турбокомпрессор BorgWarner",
        correct: false,
      },
      {
        name: "Чугунные гильзы цилиндров и турбокомпрессор Garrett",
        correct: true,
      },
      {
        name: "Чугунный турбокомпрессор Garett и алюминиевый блок цилиндров",
        correct: false,
      },
    ],
  },
  {
    question:
      "Выберите вариант с основными конкурентами одноклассниками модели Omoda S5 ?",
    utpNo: 105,
    answers: [
      {
        name: "Hyundai Elantra, Kia Cerato, Geely Emgrand, JAC J7",
        correct: true,
      },
      {
        name: "Hyundai Accent, Kia Rio, Changan Alsvin, Chevrolet Onix",
        correct: false,
      },
      {
        name: "Toyota Camry, Chery Arrizo 8, Hyundai Sonata",
        correct: false,
      },
      {
        name: "Chevrolet Cobalt, Volkswagen Polo, Toyota Corolla",
        correct: false,
      },
    ],
  },
  {
    question:
      "Основное преимущество базовых комплектаций Omoda S5 в сравнении с конкурентами?",
    utpNo: 106,
    answers: [
      {
        name: "Кузов Omoda S5 больше чем у конкурентов Elantra, Cerato, J7",
        correct: false,
      },
      {
        name: "Самое богатое оснащение начальных комплектаций «теплыми опциями» в сравнении с конкурентами",
        correct: true,
      },
      {
        name: "В Omoda S5 кожаная отделка сидений лучше качества чем у конкурентов",
        correct: false,
      },
      {
        name: "Omoda S5 имеет подогрев всех сидений и руля начиная с комплектации Life",
        correct: false,
      },
    ],
  },
  {
    question:
      "Назовите основные сходства кузовов Range Rover Evoque и Jaecoo J7 ?",
    utpNo: 107,
    answers: [
      {
        name: "Корпуса боковых зеркал, задних крыльев и колёсных дисков практически идентичны",
        correct: false,
      },
      {
        name: "Размер кузова и внутри салонное пространство практически идентичны",
        correct: false,
      },
      {
        name: "Передняя решетка радиатора, фары головного света, крышка багажной двери",
        correct: false,
      },
      {
        name: "Электро-выдвижные ручки, боковая линия кузова, скошенная форма окон и крыши",
        correct: true,
      },
    ],
  },
  {
    question:
      "Какой дисплей мультимедиа и центральный процессор устанавливается в Jaecoo J7 Premium?",
    utpNo: 108,
    answers: [
      {
        name: "Дисплей 14,8 дюймов и 8-ми ядерный процессор Snapdragon Qualcomm 8155",
        correct: true,
      },
      {
        name: "Дисплей 12,8 дюймов и 4-х ядерный процессор Snapdragon Qualcomm 4155 ",
        correct: false,
      },
      {
        name: "Дисплей 14,8 дюймов и 16-ти ядерный процессор Snapdragon Qualcomm 16155",
        correct: false,
      },
      {
        name: "Дисплей 12,8 дюймов и 8-ми ядерный процессор Intel Core i7",
        correct: false,
      },
    ],
  },
  {
    question:
      "Основные преимущества Jaecoo J7 перед конкурентами одноклассниками?",
    utpNo: 109,
    answers: [
      {
        name: "Самый большой: клиренс, объем багажника, объем топливного бака",
        correct: false,
      },
      {
        name: "Самые новые: Голосовой помощник, сплав металла кузова, аудио система Sony",
        correct: false,
      },
      {
        name: "Самый опциональный: 540 обзор, HUD проекция, память сидений, 7 режимов вождения",
        correct: true,
      },
      {
        name: "Самый умный: Система автоматической парковки, распознавание дорожных камер и пешеходов",
        correct: false,
      },
    ],
  },
];

// id, utpNo, username, date, correct
