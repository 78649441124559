import React, { Component } from "react";
import {
  Container,
  LinearProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  InputLabel,
  InputAdornment,
  Input,
  IconButton,
  DialogContentText,
  DialogActions,
  Snackbar,
} from "@material-ui/core";
import axios from "axios";
import { AUTH_API_URL } from "../lib/env";
import DefaultAvatar from "../img/default-avatar.jpg";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { phrases, validateEmail } from "../lib/util";
import CommentIcon from "@material-ui/icons/FormatQuote";
import moment from "moment";
import { Edit, Phone, PhotoCamera } from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import MaskedTextInput from "../components/MaskedTextInput";

class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      token: localStorage.getItem("token"),
      phrase: "",
      isLoading: false,
      isChangePasswordDialogOpen: false,
      snackbarVisible: false,
      snackbarErrorMessage: "",
      showPassword: false,
      newPassword: "",
      oldPassword: "",
      newPhone: "",
      newEmail: "",
      phoneDialogVisible: false,
      passwordExpired: false,
      emailDialogVisible: false,
      userData: {
        username: null,
        firstName: null,
        lastName: null,
        phone: null,
        division: null,
        position: null,
        birthDate: null,
        email: null,
        photo: null,
        male: null,
        iin: null,
      },
    };

    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
    this.changePassword = this.changePassword.bind(this);
  }

  handleClickShowPassword() {
    this.setState({
      showPassword: this.state.showPassword ? false : true,
    });
  }

  handleDialogClose() {
    this.setState({
      isChangePasswordDialogOpen: false,
    });
  }

  changePassword() {
    this.setState({ isLoading: true });

    let self = this;
    axios
      .post(
        AUTH_API_URL + "/users/changeMyPassword",
        {
          oldPassword: self.state.oldPassword,
          newPassword: self.state.newPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then(function (response) {
        console.log(response.data);
        self.setState({
          isLoading: false,
          isChangePasswordDialogOpen: false,
        });
      })
      .catch(function (error) {
        self.setState({ isLoading: false });
        if (error.response.data.message === "same password") {
          self.setState({
            snackbarVisible: true,
            snackbarErrorMessage: "Новый и старый пароль совпадают",
          });
        }
        console.log(error.message);
        if (error.message.includes("401")) {
          self.setState({
            snackbarVisible: true,
            snackbarErrorMessage:
              "Неверный логин или пароль. Проверьте, нет ли опечаток.",
          });
        }
      });
  }

  saveEmail() {
    if (!validateEmail(this.state.newEmail)) {
      this.setState({
        snackbarVisible: true,
        snackbarErrorMessage: "Некорректный формат email",
      });
      return;
    }

    if (
      !this.state.newEmail.endsWith("@aster.kz") &&
      !this.state.newEmail.endsWith("@aster-auto.kz")
    ) {
      this.setState({
        snackbarVisible: true,
        snackbarErrorMessage: "Укажите корпоративный email",
      });
      return;
    }

    let self = this;

    axios
      .patch(
        AUTH_API_URL + "/users/editUser",
        {
          username: this.state.userData.username,
          email: this.state.newEmail,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then(function (response) {
        console.log(response.data);
        let userData = self.state.userData;
        userData.email = self.state.newEmail;

        self.setState({
          emailDialogVisible: false,
          userData: userData,
        });
      })
      .catch(function (error) {
        console.log(error.message);
      });
  }

  getUserByToken() {
    this.setState({ isLoading: true });
    let self = this;
    axios
      .get(AUTH_API_URL + "/users/getUserByToken", {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      })
      .then(function (response) {
        console.log(response.data);
        self.setState({
          userData: response.data,
          birthDate:
            moment(response.data.birthDate).format("DD.MM.YYYY") ===
            "Invalid date"
              ? response.data.birthDate
              : moment(response.data.birthDate).format("DD.MM.YYYY"),
          isLoading: false,
        });
      })
      .catch(function (error) {
        self.setState({ isLoading: false });
        console.log(error.message);
        if (error.message.includes("401")) {
          self.setState({
            snackbarVisible: true,
            snackbarErrorMessage:
              "Неверный логин или пароль. Проверьте, нет ли опечаток.",
          });
        }
      });
  }

  handleChange = (name) => (event) => {
    this.setState(
      {
        [name]: event.target.value,
      },
      () => console.log(this.state)
    );
  };

  componentDidMount() {
    console.log("profile param");
    console.log(this.props.history.location.state);
    if (this.props.history.location.state) {
      if (this.props.history.location.state.changePassword) {
        this.setState({
          isChangePasswordDialogOpen: true,
          passwordExpired: true,
        });
      }
      if (this.props.history.location.state.passwordToChange) {
        this.setState({
          oldPassword: this.props.history.location.state.passwordToChange,
        });
      }
    }

    this.getUserByToken();
    this.setState({
      phrase: phrases[Math.floor(Math.random() * phrases.length)],
    });
  }

  saveUserData() {
    let self = this;
    axios
      .patch(
        AUTH_API_URL + "/users/editUser",
        {
          username: this.state.userData.username,
          phone: this.state.userData.phone,
          email: this.state.userData.email,
          birthDate: this.state.userData.birthDate,
          firstName: this.state.userData.firstName,
          lastName: this.state.userData.lastName,
          photo: this.state.userData.photo,
        },
        {
          headers: {
            Authorization: `Bearer ${this.state.token}`,
          },
        }
      )
      .then(function (response) {
        console.log(response.data);
        self.getUserByToken();
      })
      .catch(function (error) {
        console.log(error.message);
      });
  }

  handleUploadPhoto(files) {
    console.log(files);
    const file = files[0];
    const formData = new FormData();
    formData.append("file", file);

    let self = this;

    axios
      .post(AUTH_API_URL + "/files/uploadFile", formData, {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      })
      .then(function (response) {
        console.log(response.data);
        let userData = { ...self.state.userData };
        userData.photo = response.data.fileDownloadUri;
        self.setState({ userData }, () => {
          self.saveUserData();
        });
      })
      .catch(function (error) {
        console.log(error.message);
      });
  }

  saveNewPhone() {
    if (this.state.newPhone.length !== 18) {
      this.setState({
        snackbarVisible: true,
        snackbarErrorMessage: "Некорректный формат телефона",
      });
      return;
    }

    console.log(this.state.newPhone);
    let userData = { ...this.state.userData };
    userData.phone = this.state.newPhone;
    this.setState(
      {
        userData,
        newPhone: "",
        phoneDialogVisible: false,
      },
      () => {
        this.saveUserData();
      }
    );
  }

  signOut() {
    this.setState({ anchorEl: null });
    localStorage.removeItem("token");
    localStorage.removeItem("blockNotifications");
    this.props.history.push("/signin");

    // console.log(this.valuationFrameRef);
    //
    // if (this.valuationFrameRef.current !== null) {
    //   this.valuationFrameRef.contentWindow.postMessage('logout', '*');
    //   console.log('logout sent');
    // } else {
    //   this.props.history.push('/signin');
    // }
  }

  render() {
    return (
      <Container className="container">
        <h1>Профиль</h1>
        {this.state.isLoading === true ? (
          <LinearProgress />
        ) : (
          <div className="block">
            <div className="user">
              <div className="user__photo">
                <div className="user__photo-container">
                  <img
                    src={
                      this.state.userData.photo === null ||
                      this.state.userData.photo === ""
                        ? DefaultAvatar
                        : this.state.userData.photo
                    }
                    alt="avatar"
                  />
                </div>
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="label"
                >
                  <input
                    hidden
                    accept="image/*"
                    type="file"
                    onChange={(event) =>
                      this.handleUploadPhoto(event.target.files)
                    }
                    onClick={(event) => {
                      event.target.value = null;
                    }}
                  />
                  <PhotoCamera />
                </IconButton>
                <h2>
                  {this.state.userData.firstName} {this.state.userData.lastName}
                </h2>
                <p>@{this.state.userData.username}</p>
                {this.state.userData.phone ? (
                  <div>
                    <h3>
                      {this.state.userData.phone}
                      <IconButton
                        onClick={() =>
                          this.setState({ phoneDialogVisible: true })
                        }
                        color="primary"
                        aria-label="upload picture"
                        component="label"
                      >
                        <Edit />
                      </IconButton>
                    </h3>
                  </div>
                ) : (
                  <div>
                    <Button
                      onClick={() =>
                        this.setState({ phoneDialogVisible: true })
                      }
                      variant="outlined"
                    >
                      Добавить номер телефона
                    </Button>
                  </div>
                )}
                {this.state.userData.email ? (
                  <div>
                    <h3>
                      {this.state.userData.email}
                      <IconButton
                        onClick={() =>
                          this.setState({ emailDialogVisible: true })
                        }
                        color="primary"
                        aria-label="edit email"
                        component="label"
                      >
                        <Edit />
                      </IconButton>
                    </h3>
                  </div>
                ) : (
                  <div>
                    <Button
                      onClick={() =>
                        this.setState({ emailDialogVisible: true })
                      }
                      variant="outlined"
                    >
                      Добавить email
                    </Button>
                  </div>
                )}
              </div>
              <div className="user__text">
                <div className="phrase">
                  <div className="phrase__text">
                    <CommentIcon />
                    <p>{this.state.phrase.text}</p>
                  </div>
                  <div className="phrase__author">
                    <p>{this.state.phrase.author}</p>
                  </div>
                </div>
                {this.state.birthDate !== null &&
                  this.state.birthDate !== "" && (
                    <p>
                      Дата рождения: <span>{this.state.birthDate}</span>
                    </p>
                  )}
                {this.state.userData.iin !== null &&
                  this.state.userData.iin !== "" && (
                    <p>
                      ИИН: <span>{this.state.userData.iin}</span>
                    </p>
                  )}
                {this.state.userData.email !== null &&
                  this.state.userData.email !== "" && (
                    <p>
                      Email: <span>{this.state.userData.email}</span>
                    </p>
                  )}
                {this.state.userData.phone !== null &&
                  this.state.userData.phone !== "" && (
                    <p>
                      Телефон: <span>{this.state.userData.phone}</span>
                    </p>
                  )}
                {this.state.userData.division !== null &&
                  this.state.userData.division !== "" && (
                    <p>
                      Подразделение: <span>{this.state.userData.division}</span>
                    </p>
                  )}
                {this.state.userData.position !== null &&
                  this.state.userData.position !== "" && (
                    <p>
                      Позиция: <span>{this.state.userData.position}</span>
                    </p>
                  )}
              </div>
            </div>
            <div className="btn-container">
              <Button
                className="btn"
                onClick={() =>
                  this.setState({ isChangePasswordDialogOpen: true })
                }
              >
                Сменить пароль
              </Button>
              <Button
                className="btn btn_success"
                onClick={() => this.props.history.push("/profile/calendar")}
              >
                Календарь событий
              </Button>
            </div>
          </div>
        )}

        <Dialog
          className="dialog"
          onClose={(e, reason) => {
            if (!reason === "backdropClick" && !reason == "escapeKeyDown") {
              this.handleDialogClose();
            }
          }}
          aria-labelledby="simple-dialog-title"
          open={this.state.isChangePasswordDialogOpen}
        >
          <DialogTitle id="simple-dialog-title">
            {!this.state.passwordExpired
              ? "Сменить пароль"
              : "Пароль устарел, измените пароль!"}
          </DialogTitle>
          <DialogContent>
            <FormControl>
              <InputLabel htmlFor="old-password" required={true}>
                Старый пароль
              </InputLabel>
              <Input
                id="old-password"
                type={this.state.showPassword ? "text" : "password"}
                value={this.state.oldPassword}
                onChange={this.handleChange("oldPassword")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={this.handleClickShowPassword}
                    >
                      {this.state.showPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <FormControl>
              <InputLabel htmlFor="new-password" required={true}>
                Новый пароль
              </InputLabel>
              <Input
                id="new-password"
                type={this.state.showPassword ? "text" : "password"}
                value={this.state.newPassword}
                onChange={this.handleChange("newPassword")}
                autoFocus={true}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={this.handleClickShowPassword}
                    >
                      {this.state.showPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <div className="btn-container">
              <Button
                className="btn btn_small btn_success"
                onClick={this.changePassword}
              >
                Сохранить
              </Button>
              {!this.state.passwordExpired ? (
                <Button
                  className="btn btn_small btn_danger"
                  onClick={() =>
                    this.setState({ isChangePasswordDialogOpen: false })
                  }
                >
                  Закрыть
                </Button>
              ) : (
                <Button
                  className="btn btn_small btn_danger"
                  onClick={() => this.signOut()}
                >
                  Выход
                </Button>
              )}
            </div>
          </DialogContent>
        </Dialog>

        <Dialog
          open={this.state.phoneDialogVisible}
          onClose={() => this.setState({ phoneDialogVisible: false })}
        >
          <DialogTitle>Введите номер телефона</DialogTitle>
          <DialogContent>
            <MaskedTextInput
              name={"phone"}
              mask={"+7 (999) 999-99-99"}
              value={this.state.newPhone}
              change={(e) => this.setState({ newPhone: e.target.value })}
              type={"tel"}
              label="Номер телефона"
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.setState({
                  newPhone: "",
                  phoneDialogVisible: false,
                });
              }}
            >
              Закрыть
            </Button>
            <Button
              onClick={() => {
                this.saveNewPhone();
              }}
            >
              Сохранить
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.emailDialogVisible}
          onClose={() => this.setState({ emailDialogVisible: false })}
        >
          <DialogTitle>Введите email</DialogTitle>
          <DialogContent>
            <TextField
              name="email"
              value={this.state.newEmail}
              onChange={(e) => this.setState({ newEmail: e.target.value })}
              type={"email"}
              label="email"
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.setState({
                  emailDialogVisible: false,
                });
              }}
            >
              Закрыть
            </Button>
            <Button
              onClick={() => {
                this.saveEmail();
              }}
            >
              Сохранить
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={this.state.snackbarVisible}
          autoHideDuration={5000}
          onClose={() => this.setState({ snackbarVisible: false })}
          ContentProps={{
            "aria-describedby": "message-id",
            style: { backgroundColor: "red" },
          }}
          message={
            <span id="message-id">{this.state.snackbarErrorMessage}</span>
          }
          action={[]}
        />
      </Container>
    );
  }
}

export default Profile;
