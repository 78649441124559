import axios from "axios";
export const AUTH_API_URL = window.location.hostname === 'localhost' ? 'https://test.asterx.kz/api-auth' : window.location.protocol + '//' + window.location.host + '/api-auth';
// export const AUTH_API_URL = 'https://asterx.kz/api-auth';
export const NOTIFICATION_API_URL = window.location.hostname === 'localhost' ? 'https://test.asterx.kz/api-notification' : window.location.protocol + '//' + window.location.host + '/api-notification';
export const MISC_API_URL = window.location.hostname === 'localhost' ?
  'https://test.asterx.kz/api-misc' : window.location.protocol + '//' + window.location.host + '/api-misc';
export const ML_API_URL = 'https://asterx.kz/api-ml';
export const SALES_API_URL = window.location.hostname === 'localhost' ? 'https://test.asterx.kz/api-sales' :
    window.location.protocol + '//' + window.location.host + '/api-sales';
export const CP_PAYMENT_API_URL = window.location.hostname === 'localhost' ? 'https://test.asterx.kz/api-cp-payment' :
    window.location.protocol + '//' + window.location.host + '/api-cp-payment';
export const CS_CONTRACTS_API_URL = window.location.hostname === 'localhost' ? 'https://test.asterx.kz/api-cs-contracts' :
    window.location.protocol + '//' + window.location.host + '/api-cs-contracts';

export async function getUserRoleByToken(token) {
  return await axios.get(AUTH_API_URL + '/users/getUserByToken', {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
}

export async function subscribeToNotifications(token, fcm_token) {
  return await axios.post(NOTIFICATION_API_URL + '/notification/addToken?to_token=' + fcm_token, {},
    {headers: {Authorization: `Bearer ${token}`}});
}