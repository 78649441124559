import React, { Component } from "react";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import moment from "moment";
import { UTP_QS } from "../lib/constants";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

import GreatIcon from "@material-ui/icons/SentimentSatisfiedAlt";
import OkIcon from "@material-ui/icons/SentimentDissatisfied";
import BadIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import { parseJwt } from "../lib/util";
import axios from "axios";
import { MISC_API_URL } from "../lib/env";

class Utp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      utpQuestions: UTP_QS.sort(() => Math.random() - Math.random()).slice(
        0,
        3
      ),
      utpAnswerName0: null,
      utpAnswerCorrect0: null,
      utpAnswerName1: null,
      utpAnswerCorrect1: null,
      utpAnswerName2: null,
      utpAnswerCorrect2: null,
      pageNo: 0,
      finished: false,
      showUtpDialog: false,
    };
  }

  componentDidMount() {
    this.setState({
      showUtpDialog: this.checkUtpDialogShow(),
    });
  }

  checkUtpDialogShow() {
    console.log(this.props);

    if (!localStorage.getItem("token")) {
      return false;
    }

    let user = parseJwt(localStorage.getItem("token"));
    console.log(user);

    let checkRole = false;
    for (let role of user.roleList) {
      if (
        (role.project.code === "CS_NEWAUTO" && role.code === "MANAGER") ||
        (role.project.code === "CS_NEWAUTO" && role.code === "HEAD_SALES") ||
        (role.project.code === "CS_NEWAUTO" && role.code === "ATZ") ||
        (role.project.code === "CS_NEWAUTO" &&
          role.code === "SUPER_HEAD_SALES") ||
        (role.project.code === "CS_NEWAUTO" && role.code === "FIN_MANAGER")
      ) {
        checkRole = true;
      }
    }

    if (
      ["i.bulanov", "r.grabinskii", "admin", "v.manukyan"].includes(user.sub)
    ) {
      checkRole = false;
    }

    if (!checkRole) {
      return false;
    }

    let lastUtpDate = localStorage.getItem("lastUtpDate");
    let currentDate = moment(new Date()).format("DD.MM.YYYY");

    if (!lastUtpDate) {
      return true;
    }

    if (lastUtpDate !== currentDate) {
      return true;
    }

    return false;
  }

  saveUtpAnswers() {
    let user = parseJwt(localStorage.getItem("token"));
    axios
      .post(
        MISC_API_URL + "/utp/saveUtp",
        {
          utpNo: this.state.utpQuestions[0].utpNo,
          username: user.sub,
          correct: this.state.utpAnswerCorrect0,
        },
        {
          headers: {
            apiKey: "jhfgF8765G67%f&%^$R",
          },
        }
      )
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error.message);
      });

    axios
      .post(
        MISC_API_URL + "/utp/saveUtp",
        {
          utpNo: this.state.utpQuestions[1].utpNo,
          username: user.sub,
          correct: this.state.utpAnswerCorrect1,
        },
        {
          headers: {
            apiKey: "jhfgF8765G67%f&%^$R",
          },
        }
      )
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error.message);
      });

    axios
      .post(
        MISC_API_URL + "/utp/saveUtp",
        {
          utpNo: this.state.utpQuestions[2].utpNo,
          username: user.sub,
          correct: this.state.utpAnswerCorrect2,
        },
        {
          headers: {
            apiKey: "jhfgF8765G67%f&%^$R",
          },
        }
      )
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error.message);
      });

    let currentDate = moment(new Date()).format("DD.MM.YYYY");
    localStorage.setItem("lastUtpDate", currentDate);

    this.setState({
      showUtpDialog: false,
    });
  }

  handleChange(pageNo, event) {
    let correct = this.state.utpQuestions[pageNo].answers.filter(
      (answer) => answer.name === event.target.value
    )[0].correct;

    this.setState({
      [`utpAnswerName${pageNo}`]: event.target.value,
      [`utpAnswerCorrect${pageNo}`]: correct,
    });
  }

  navNextQuestion() {
    let nextPage = this.state.pageNo + 1;
    if (nextPage === 3) {
      this.setState({
        finished: true,
        pageNo: 0,
      });
      return;
    }

    this.setState({ pageNo: nextPage });
  }

  render() {
    let {
      pageNo,
      utpQuestions,
      finished,
      utpAnswerCorrect0,
      utpAnswerCorrect1,
      utpAnswerCorrect2,
    } = this.state;

    return (
      <div>
        <Dialog open={this.state.showUtpDialog}>
          <DialogTitle>
            Опросник по УТП {!finished && pageNo + 1 + "/3"}
          </DialogTitle>
          <DialogContent>
            <div>
              <div>
                {!finished ? (
                  <div>
                    <div style={{ fontSize: "20px", marginBottom: "20px" }}>
                      {utpQuestions[pageNo].question}
                    </div>
                    <FormControl component="fieldset">
                      <RadioGroup
                        name="q"
                        value={this.state[`utpAnswerName${pageNo}`]}
                        onChange={this.handleChange.bind(this, pageNo)}
                      >
                        {utpQuestions[pageNo].answers.map((answer) => (
                          <FormControlLabel
                            value={answer.name}
                            control={<Radio />}
                            label={answer.name}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                    <div>
                      <Button
                        onClick={this.navNextQuestion.bind(this)}
                        className="button_regular_color button"
                        style={
                          this.state[`utpAnswerName${pageNo}`] === null
                            ? { marginTop: "10px", display: "none" }
                            : { marginTop: "10px" }
                        }
                      >
                        {pageNo < 2 ? "Следующий вопрос" : "Завершить"}
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div style={{ textAlign: "center" }}>
                    {!utpAnswerCorrect0 &&
                    !utpAnswerCorrect1 &&
                    !utpAnswerCorrect2 ? (
                      <div>
                        <div>
                          <BadIcon
                            style={{ fontSize: "100px", color: "#ca2a2a" }}
                          />
                        </div>
                        <div></div>
                      </div>
                    ) : utpAnswerCorrect0 &&
                      utpAnswerCorrect1 &&
                      utpAnswerCorrect2 ? (
                      <div>
                        <div>
                          <GreatIcon
                            style={{ fontSize: "100px", color: "#13a20a" }}
                          />
                        </div>
                        <div></div>
                      </div>
                    ) : (
                      <div>
                        <div>
                          <OkIcon
                            style={{ fontSize: "100px", color: "#fa6f25" }}
                          />
                        </div>
                        <div></div>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div style={{ textAlign: "center", marginTop: "30px" }}>
                {finished && (
                  <Button
                    onClick={this.saveUtpAnswers.bind(this)}
                    className="button_regular_color button"
                  >
                    Закрыть
                  </Button>
                )}
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default Utp;
